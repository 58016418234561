import React from 'react';

export default () => (
  <section className='container-fluid'>
          <div className='row m-2-hor'>
            
            <div className='col-md-6 pt-5'>
              <div className='col-home'>
                <div className='thumb'>
                  <img
                      src="./img/home.jpg"
                      className="img-fluid"
                      alt="#"
                    />
                </div>
              </div>
            </div>

            <div className='col-md-6'>
              <div className='dflex-center'>
                <div className='col-home mt-md-0 mt-5'>
                  <div className='heading'>Meet Dr. Michelle</div>
                  <div className='content'>
                    <p></p>Michelle understands the importance of taking a holistic approach to athletic care, recognizing that before you are an athlete, you are an individual with unique physiological and psychological needs. When athletes train, compete, or perform, all of their physiological systems are put under stress, therefore it is crucial to address your overall health to optimize your performance and health. She believes in providing comprehensive care that takes into account the physical, mental, and emotional aspects of your health. 

                    <p></p> Specific areas of treatment include:
                  </div>
                  <ul className='list-home'>
                    <li>Injury pervention, recovery & rehabilitation</li>
                    <li>Athletic performance enhancement</li>
                    <li>Hormone imbalances</li>
                    <li>Cosmetic aesthetics</li>
                    <li>Gastrointestinal dysfunction</li>
                    <li>Healthy weight maintenance</li>
                    <li>Stress, depression & anxiety relief</li>
                    <li>... And more!</li>
                  </ul>
                </div>
              </div>
            </div>
            
          </div>
        </section>
);
