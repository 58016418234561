import React, { Component, createRef, Fragment } from "react";
import {
  Hero,
  Title,
  TechniqueTitle,
  ClientTag,
  CaseWrapper,
  BackButton,
  BackArrow
} from "../styles/Case.styles";

import Reveal from 'react-reveal/Reveal';
import { withRouter } from "react-router";
import Footer from '../components/Footer';
import { Efectr, Efectr1, Efectr2  } from "../styles/effect.styles";

export const ScrollTop = ({ children, location }) => {
  React.useEffect(() => window.scrollTo(0, 0), [location])
  return children
}

class Case extends Component {
  constructor(props) {
    super(props);
    this.introRef = createRef();

    this.state = {
      toBack: false,
      introTop: 0,
      hasBackground: false,
      animateCase: ""
    };
  }

  componentDidUpdate() {
    if (this.state.toBack) {
      setTimeout(() => {
        this.props.setNavBackground(false);
        this.props.history.push("/blog");
      }, 400);
    }
    if (this.state.animateCase) {
      setTimeout(() => {
        this.props.setNavBackground(false);

        this.props.history.push(this.state.animateCase);
        window.scrollTo(0, 0);
      }, 400);
    }
  }


  render() {
    return (
      <Fragment>
      <Efectr/>
      <Efectr1/>
      <Efectr2/>
      <ScrollTop>
        <CaseWrapper>
        <Reveal effect="fadeIn">
          <Hero className='mainhero' style={{ backgroundImage: "url('./img/news/nplex2.jpg')" }}>
              <div className='herocaption'>
                <BackButton className='backdetail'
                  onClick={() => this.setState({ toBack: true, hasBackground: false })}
                  toBack={this.state.toBack}
                  hasBackground={this.state.hasBackground}>
                  <BackArrow src="./img/back.png" alt="Back to blog" />
                  <span>Back </span>
                </BackButton>
                <div className='container'>
                  <div className='row'>
                    <div className='col-md-12 pl-md-0'>
                      <ClientTag className='date'>July 02, 2023</ClientTag>
                      <Title>Passing NPLEX I & II</Title>
                      <TechniqueTitle>
                       How I successfully passed NPLEX I & II 
                      </TechniqueTitle>
                    </div>
                  </div>
                </div>
              </div>
          </Hero>
          </Reveal>

        <section className='container' id='detailproject'>
          <div className='row'>
            <div className='col-md-12'>
              <p className='content'>The bottom line is … NPLEX exams are HARD! 
              Many concepts with complex details in a long exam makes it feel like running a marathon. 
              Therefore, I believe the best way to prepare for exams is like a marathon!</p>
              <p className='content'>The first step is to get ORGANIZED! Determine the materials you are 
              going to use early (ideally 4-5 months before the exam date). This will give you additional 
              time to look through the materials and see how they are organized and reorganize them to 
              your preference. Remember, you have studied and passed many exams before NPLEX I & II. 
              You have a toolkit of how you study best and what techniques will work best for you. 
              Do not change your best forms of studying because they worked well for someone else. 
              I will still provide what I used for NPLEX I & II - but please take it with a grain 
              of salt as my way of studying may not work for you. </p>
              <p className='content'>One piece of valuable pre-study advice is: make yourself a daily schedule complete with 
              when, where, and what you will study, eat, exercise and sleep each day while you are studying. 
              Take these exams seriously, but do not burn yourself out. Remember, there will be good and 
              bad days - but there is always something good in the bad days!  </p>
              <div className='single-img '>
                <img
                  src="./img/news/npleximg1.jpg"
                  className="img-fluid"
                  alt="Imageteam"
                />
              </div>
              <h3 className='my-5'>The Preparation ... 3 months until exam day</h3>
              <p className='content'>This time should be dedicated to reviewing, re-learning, 
              and gathering all the knowledge possible. Be a sponge and absorb everything. Remember 
              to also take days off. For me, Saturday and Sundays were days for relaxation and time with 
              family and/or friends.</p>
              <p className='content'>While studying for NPLEX I, I dedicated each week to a new system of 
              the body. For example week 1 was the cardiovascular system. On Monday, I would read the 
              corresponding USMLE Step 1 chapter. On Tuesday, I would review all of the physiology associated 
              for that system. On Wednesday, I watched Dr. Anderson’s lecture videos correlating to the system, 
              watch Pass NPLEX videos correlated to the system, and review my personal course notes that were correlated 
              to the system of study for that week. Thursday and Friday were practice quiz days with Board Vitals and my 
              personally made cue-card decks. </p>
              <p className='content'>While studying for NPLEX II, each day was a new system. I used the Core Knowledge 
              textbook to divide my days equivalent to their chapters. I spent one month reading Core Knowledge and 
              watching Dr. Anderson’s videos consecutively. I spent the next month doing Board Vital quizzes  and rewatching 
              Dr. Anderson’s videos. The last month of the three, I really studied the Wild Brilliance cue cards and reread 
              Core Knowledge.</p>
            </div>
            <div className='image-container'>
              <div className='single-img pimg-5 col-12 col-md-6'>
                <img
                  src="./img/news/npleximg2.jpg"
                  className="img-fluid"
                  alt="Imageteam"
                />
              </div>
              <div className='single-img pimg-5 col-12 col-md-6'>
                <img
                  src="./img/news/npleximg3.jpg"
                  className="img-fluid"
                  alt="Imageteam"
                />
              </div>
        

            
            </div>
            <div className='col-md-12'>
              <h3 className='my-5'>The Final Sprint to the End ... 1 month until exam day</h3>
              <p className='content'>REVIEW! REVIEW! REVIEW! I cannot stress this enough. This time should 
              be dedicated to catching up on missed topics and solidifying material you are still not as 
              comfortable with. Do as many practice quizzes as you can and review your cue cards. Mark the 
              cards you continually mess up on and review those ones first and last every day. </p>
              <p className='content'>Remember to take care of your body. Eat nutritious meals, move your body 
              daily, and get good sleep! Although board exams are important, your health matters the most! </p>
            </div>
          
            <div className='col-md-12'>
             <div className='share'>
              <span className='heading'>
               Share this blog post :
              </span>
              <span className='content'> 
              <a href="https://www.facebook.com/sharer/sharer.php?u=drmichellemaliknd.com/NPLEXBlog" target="_blank" rel="noopener noreferrer">  
                <i className="fa fa-facebook-f"></i>
                </a>  
              </span>
              <span className='content'> 
              <a href="https://www.instagram.com/dr.michellemalik.nd" target="_blank" rel="noopener noreferrer">   
                <i className="fa  fa-instagram"></i>
                </a>
              </span>
             </div>
            </div>
          </div>
        </section>         
        </CaseWrapper>

      </ScrollTop>

      <Footer />

      </Fragment>
    );
  }
}

export default withRouter(Case);
