import React from "react";
import { withRouter } from "react-router-dom";
import Reveal from 'react-reveal/Reveal';

import Abouthome from '../components/Abouthome';
import Footer from '../components/Footer';

import { Efect, Efect1, Efect2  } from "../styles/effect.styles";
  

const About = ({ history }) => {

  return (
    <div>
      <Efect/>
      <Efect1/>
      <Efect2/>

      <div className="jumbotron head"/>

      <Reveal effect="fadeIn">
        <section className='jumbotron imgtop'>
        	<img
	          src="./img/imgabout.jpg"
	          className="img-fluid"
	          alt="#"
	        />
        </section>
       </Reveal>

      <Reveal effect="fadeIn">
        <section className='container-fluid pb-0'>
	        <div className='row m-2-hor'>
	        	<div className='col-md-4'>
	        		<h1>About Dr. Michelle Malik, ND</h1>
	        	</div>
	        	<div className='col-md-8'>
	        		<div className='content'>Michelle holds a Bachelor of Science in Biology, Psychology & Human Nutrition 
              from the University of Waterloo, and a Doctor of Naturopathy from the Canadian College of Naturopathic Medicine 
              with a special focus in holistic female sports medicine. Additionally, Michelle is an Internationally Certified 
              Personal Trainer through the International Sports Sciences Association.
              With her strong educational background, Michelle is equipped to provide personalized care to female athletes, 
              optimizing their health and performance goals through a holistic approach. 
	        		</div>
	        	</div>
	        </div>
	    </section>
	   </Reveal>

	  <Reveal effect="fadeInUp">
        <Abouthome/>
      </Reveal>

      <Footer />

    </div>
    
  );
};

export default withRouter(About);
