import React from 'react';

export default () => (
  <footer className='container-fluid black_more'>
  <div className='bg-footer'>
    <img src='./img/footer-img.png' alt='bg-footer'/>
  </div>
    <div className='row m-2-hor'>
      <div className='col-md-4'>
        <div className='footer-col'>
          <div className='heading'>
            <h2>My Mission</h2>
          </div>
          <div className='content'>
             <p>Before you are an athlete - you are a person. Dr. Michelle is dedicated to helping you achieve optimal performance inside and out.</p>
          </div>
          <div className='link-call' onClick={()=> window.open("mailto:drmichellemaliknd@gmail.com", "_self")}>drmichellemaliknd@gmail.com</div>
        </div>
      </div>
      <div className='col-md-2'>
        <div className='footer-col'>
          <div className='heading'>
            Useful&nbsp;links
          </div>
          <div className='content'>
             <div className='link'>
              <a href="/">Home</a>
              </div>
             <div className='link'>
              <a href="/about">About</a>
              </div>
             <div className='link'>
              <a href="/blog">Blog</a>
              </div>
             <div className='link'>
              <a href="/contact">Contact</a>
              </div>
          </div>
        </div>
      </div>

      <div className='col-md-4'>
        <div className='footer-col'>
          <div className='heading'>
            Follow Me
          </div>
          <div className='content'>
            <p>Connect with me through social media:</p>
            <div className='socialicon color=#d8b861'>
              <a href="https://www.linkedin.com/in/michellecmalik" target= "_blank" rel="noopener noreferrer">
            <span className="shine"></span>
              <i className="fa fa-linkedin"></i>
              </a>
            </div>
            <div className='socialicon'>
              <a href="https://www.instagram.com/dr.michellemalik.nd" target = "_blank" rel="noopener noreferrer">
            <span className="shine"></span>
              <i className="fa  fa-instagram"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className='subfooter'>
      <div className='row m-2-hor'>
        <div className='col-md-6'>
          <div className='content'>© Copyrights 2023 dr.michellemaliknd All rights reserved.</div>
        </div>
      </div>
    </div>
  </footer>
);
