import React from 'react';

export default () => (
  <section className='container-fluid bggray py-4'>
        <div className='row m-2-hor'>

          <div className='col-lg-3 col-md-6'>
            <div className='featurehome'>
              <div className='img'>
                <i className="fa fa-comments-o"></i>
              </div>
              <div className='text'>
                Schedule an Appointment
              </div>
            </div>
          </div>

          <div className='col-lg-3 col-md-6'>
            <div className='featurehome'>
              <div className='img'>
                <i class="fa fa-list-alt"></i>
              </div>
              <div className='text'>
                Develop a Perosnalized Treatment Plan
              </div>
            </div>
          </div>

          <div className='col-lg-3 col-md-6'>
            <div className='featurehome'>
              <div className='img'>
                <i className="fa fa-cogs"></i>
              </div>
              <div className='text'>
                Engage in Plan & Follow Up
              </div>
            </div>
          </div>

          <div className='col-lg-3 col-md-6'>
            <div className='featurehome last'>
              <div className='img'>
                <i className="fa fa-line-chart"></i>
              </div>
              <div className='text'>
                Elevate Health & Performance
              </div>
            </div>
          </div>

        </div>
      </section>
);
