import React from 'react';
import Slider from 'react-animated-slider';
import 'react-animated-slider/build/vertical.css';

const content = [
  {
    title: (
    <span style={{ color: "#FF99D8", textShadow: "2px 2px 4px #026374" }}>
      {"Dr. Michelle Malik, ND, CPT, HBSc"}
      </span>
    ),
    description: (
      <span style={{ color: "#FFFFFF" }}>{"Naturopathic Doctor"}
      </span>
    ),
    button: "More Detail",
    link: "/#",
    image: "./img/bg-1.jpg"
  },
  {
    title: (
    <span style={{ color: "#FF99D8", textShadow: "2px 2px 4px #026374" }}>
      {"Holistic Sports Medicine"}
      </span>
    ),
    description:
    <span style={{ color: "#FFFFFF" }}>{"Optimizing athletes inside & out"}</span>,
    button: "More Detail",
    link: "/#",
    image: "./img/bg-2.jpg"
  },
];

export default () => (
  <Slider className="slider-wrapper" autoplay={6000}>
            {content.map((item, index) => (
              <div
                key={index}
                className="slider-content"
                style={{ background: `url('${item.image}') no-repeat center center` }}
              >
                <div className="inner">
                  <h1>{item.title}</h1>
                  <p>{item.description}</p>
                  <button onClick={()=> window.open(item.link, "_self")}>
                    <span className="shine"></span>
                    <span>
                      {item.button}
                    </span>
                  </button>
                </div>
              </div>
            ))}
        </Slider>
);
